function getModel(callback) {

    $('#modelId').find('option').remove().end().append('<option value="">Válaszd ki a motor típusát...</option>');
    $('#manufactured_sinceId').find('option').remove().end().append('<option value="">Válaszd ki a motor gyártásának kezdetét...</option>');
    $('#model, #manufactured_sinceId').prop('disabled', true);
    var manufacturer = $('#manufacturerId').val();


    if (manufacturer == '') {
        return;
    }
    $.ajaxSetup(
        {
            headers: { 'X-CSRF-Token': $('input[name="_token"]').val() }
        });


    $.ajax(
        {
            url: 'getType',
            type: 'post',
            data: {
                manufacturer: manufacturer
            },
            success: function (response) {
                $('#modelId').find('option').remove().end();
                if (Object.keys(response).length > 1) {
                    $('#modelId').append('<option value="">Válaszd ki a motor típusát...</option>');
                }
                $.each(response, function (key, value) {
                    $('#modelId').append($("<option></option>").attr("value", key).text(value));
                });
                $('#modelId').prop('disabled', false);
                $('#modelId, #manufactured_sinceId').select2("destroy");
                $('#modelId, #manufactured_sinceId').select2({allowClear: true});
                $('#modelId').change();
                if (callback != undefined) {
                    callback();
                }
            }
        });
}
function getYears(callback) {

    $('#manufactured_sinceId').find('option').remove().end().append('<option value="">Válaszd ki a motor gyártásának kezdetét...</option>');
    $('#manufactured_sinceId').prop('disabled', true);
    var model = $('#modelId').val();
    if (model == '') {
        return;
    }
    $.ajaxSetup(
        {
            headers: {
                'X-CSRF-Token': $('input[name="_token"]').val()
            }
        });
    $.ajax(
        {
            url: 'getManufacturedSince',
            type: 'post',
            data: {
                type: model
            },
            success: function (response) {
                $('#manufactured_sinceId').find('option').remove().end();
                if (Object.keys(response).length > 1) {
                    $('#manufactured_sinceId').append('<option value="">Válaszd ki a motor típusát...</option>');
                }
                $.each(response, function (key, value) {
                    $('#manufactured_sinceId').append($("<option></option>").attr("value", key).text(value));
                });
                $('#manufactured_sinceId').prop('disabled', false);
                $('#manufactured_sinceId').select2("destroy");
                $('#manufactured_sinceId').select2({allowClear: true});
                $('#manufactured_sinceId').change();
                if (callback != undefined) {
                    callback();
                }
            }
        });
}
$(function () {
    var manufacturer = $('#manufacturerId').val();
    var model = $('#modelId').val();
    var manufactured_since = $('#manufactured_sinceId').val();


    if (manufacturer != '') {


        getModel(function () {
            if (model != '') {
                $('#modelId').val(model);
                $('#modelId').change();
                getYears(
                    function () {
                        if (manufactured_since != '') {
                            $('#manufactured_sinceId').val(manufactured_since);
                            $('#manufactured_sinceId').change();
                        }
                    }
                );
            }
        });
    }


    $('#manufacturerId').change(function () {
        getModel();
    });

    $('#modelId').change(function () {
        getYears();
    });
});